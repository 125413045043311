<template>
  <Animation3 />

  <!-- SEO Tags -->
  <teleport to="head">
    <meta name="description" :content="Seo.Description" />
    <meta name="keywords" :content="Seo.Keywords" />
    <link rel="alternate" hreflang="x-default" :href="Seo.HreflangDefault" />
    <link rel="alternate" hreflang="en-US" :href="Seo.HreflangCountry" />

    <!-- Open Graph Tags -->
    <meta property="og:title" :content="Seo.OpenGraph.Title" />
    <meta property="og:site_name" :content="Seo.OpenGraph.SiteName" />
    <meta property="og:url" :content="Seo.OpenGraph.Url" />
    <meta property="og:description" :content="Seo.OpenGraph.Description" />
    <meta property="og:type" :content="Seo.OpenGraph.Website" />
    <meta property="og:image" :content="Seo.OpenGraph.Image" />
    <!-- ./Open Graph Tags -->

    <!-- Twitter Card Tags -->
    <meta name="twitter:card" :content="Seo.TwitterCard.Card" />
    <meta name="twitter:site" :content="Seo.TwitterCard.Site" />
    <meta name="twitter:title" :content="Seo.TwitterCard.Title" />
    <meta name="twitter:description" :content="Seo.TwitterCard.Description" />
    <meta name="twitter:image" :content="Seo.TwitterCard.Image" />
    <!-- ./Twitter Card Tags -->
  </teleport>
  <!-- ./SEO Tags -->

  <main id="services" class="what dark">
    <div id="digitalTransformation" class="banner container">
      <div class="header-section">
        <h1 data-aos="fade-up" data-aos-delay="600" data-aos-duration="600">
          {{ this.content.MarqueeHeading[0] }}<br />{{
            this.content.MarqueeHeading[1]
          }}
        </h1>
      </div>
    </div>
    <div class="mark-sec">
      <Vue3Marquee pauseOnHover duration="100">
        <div class="column">
          <!--  Codefest -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-1.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect-1.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/code1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/code2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-10 transition project-display screen-flash"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-2.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row items-center"
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect2.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <img
                class="p-0 msg1"
                :src="require('@/assets/imgs/screen1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 msg2"
                :src="require('@/assets/imgs/screen2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  Joint Store -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' + require('@/assets/imgs/frame3.png') + ') center left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect3.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <div>
                <img
                  class="p-0 mb-4 joint-mob"
                  :src="require('@/assets/imgs/projects/joint-web.png')"
                  alt="No Image"
                />
                <img
                  class="p-0 mb-4 joint-web"
                  :src="require('@/assets/imgs/projects/joint-mob.png')"
                  alt="No Image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <!--  Tonino Pizzeria -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-4.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect4.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/tonino2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/tonino1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!-- Messenger App -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-10 transition project-display screen-flash"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-5.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row items-center"
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect5.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <img
                class="p-0 msg1"
                :src="require('@/assets/imgs/msg1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 msg2"
                :src="require('@/assets/imgs/msg2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  cannabbis bit -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' + require('@/assets/imgs/frame-6.png') + ') center left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row items-center"
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect6.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="group-hover:bg-gray-300 img-group">
              <img
                class="p-0 joint-mob"
                :src="require('@/assets/imgs/canbit2.png')"
                alt="No Image"
              />
              <img
                class="p-0 joint-web"
                :src="require('@/assets/imgs/canbit1.png')"
                alt="No Image"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  Hospital -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-7.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect7.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/hospital2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/hospital1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-10 transition project-display screen-flash"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-8.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row items-center"
            >
              <span class="mbs:w-56 ms5:w-56 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect8.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <img
                class="p-0 msg1"
                :src="require('@/assets/imgs/scan1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 msg2"
                :src="require('@/assets/imgs/scan2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  Wilko -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-9.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect9.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/wilko1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/wilko2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  es -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-10.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect10.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/camera1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/camera2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  es -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-11.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect11.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/stark2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/stark1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  Sharebius -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-12.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect12.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/canabis2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/canabis1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  Joint Store -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/frame-13.png') +
                ') center left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect13.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group">
              <div>
                <img
                  class="p-0 mb-4 joint-mob"
                  :src="require('@/assets/imgs/izmir.png')"
                  alt="No Image"
                />
                <img
                  class="p-0 mb-4 joint-web"
                  :src="require('@/assets/imgs/izmir-mobile.png')"
                  alt="No Image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <!--  dex -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-14.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img class="project-logo" src="@/assets/imgs/rect6.png" />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/2.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/1.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <!--  dex -->
          <div
            class="hover:bg-gray-900 bg-gray-800 justify-items-stretch z-0 transition project-display"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-15.png') + ')',
            }"
          >
            <div
              class="py-6 pl-4 pb-0 antialiased subpixel-antialiased flex flex-row space-x-0 iphone678:space-x-0 pixle:space-x-0 infinix:space-x-0 items-center"
            >
              <span class="mbs:w-56 ms5:w-64 iphone678:w-72 pixle:w-80">
                <img
                  class="project-logo"
                  src="@/assets/imgs/projects/logo/logo-ico.png"
                />
              </span>

              <span class="lg:invisible md:invisible">
                <ArrowSvg />
              </span>
            </div>
            <div class="img-group web-group">
              <img
                class="p-0 mb-4 tonino1"
                :src="require('@/assets/imgs/4.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
              <img
                class="p-0 mb-4 tonino2"
                :src="require('@/assets/imgs/3.png')"
                alt="No Image"
                :style="{ maxWidth: '100%' }"
              />
            </div>
          </div>
        </div>
      </Vue3Marquee>
    </div>

    <div class="practice">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="practice-cont">
              <h3>
                {{ this.content.MainHeading }}
                <Heart class="heart" />
              </h3>
              <button>{{ this.content.ChatButtonText }}</button>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="practice-icons">
              <div
                class="practice-box"
                data-aos="fade-left"
                data-aos-delay="600"
                data-aos-duration="600"
              >
                <div>
                  <Strategy />
                  <h5>{{ this.content.ServicesIcons[0] }}</h5>
                </div>
              </div>
              <div
                class="practice-box"
                data-aos="fade-left"
                data-aos-delay="800"
                data-aos-duration="800"
              >
                <div>
                  <Serarch />
                  <h5>{{ this.content.ServicesIcons[1] }}</h5>
                </div>
              </div>
              <div
                class="practice-box"
                data-aos="fade-left"
                data-aos-delay="1000"
                data-aos-duration="1000"
              >
                <div>
                  <Branding />
                  <h5>{{ this.content.ServicesIcons[2] }}</h5>
                </div>
              </div>
              <div
                class="practice-box"
                data-aos="fade-left"
                data-aos-delay="600"
                data-aos-duration="600"
              >
                <div>
                  <Pen />
                  <h5>{{ this.content.ServicesIcons[3] }}</h5>
                </div>
              </div>
              <div
                class="practice-box"
                data-aos="fade-left"
                data-aos-delay="800"
                data-aos-duration="800"
              >
                <div>
                  <Tools />
                  <h5>{{ this.content.ServicesIcons[4] }}</h5>
                </div>
              </div>
              <div
                class="practice-box"
                data-aos="fade-left"
                data-aos-delay="1000"
                data-aos-duration="1000"
              >
                <div>
                  <Rock />
                  <h5>{{ this.content.ServicesIcons[5] }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="services-box" class="service-top">
      <div class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span
              style="
                display: inline-flex;
                column-gap: 15px;
                align-items: center;
                margin-bottom: 32px;
              "
            >
              <EnterpriseIcon />
              <h3 style="margin-bottom: auto">
                {{ oldContent.EnterpriseApp.MainTitle }}
              </h3>
            </span>
            <h3 class="subheading">
              {{ oldContent.EnterpriseApp.SubHeading }}
            </h3>
          </div>
          <p>
            {{ oldContent.EnterpriseApp.Description[0] }}
          </p>
          <p>
            {{ oldContent.EnterpriseApp.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.EnterpriseApp.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <!-- <div data-aos="fade-down-left" class="flex justify-center">
          <img class=" " src="@/assets/illustration.png" style="width:500px; height:400px"/>
        </div> -->
        <div data-aos="fade-down-left" class="flex justify-center">
          <EADG />
        </div>
      </div>
    </section>
    <section id="services-box" class="service-top">
      <div id="appDev" class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <AppIcon />
              <h3>{{ oldContent.App.MainTitle }}</h3>
            </span>
            <h3 class="subheading">{{ oldContent.App.SubHeading }}</h3>
          </div>
          <p>
            {{ oldContent.App.Description }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.App.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div data-aos="fade-down-left" class="flex justify-center">
          <!-- <img src="@/assets/imgs/webservice.gif" /> -->
          <AppSvg />
        </div>
      </div>
    </section>

    <section class="service-top">
      <div class="grid container"></div>
    </section>

    <div class="padding-div accent"></div>
    <div id="web" class="padding-break-lr dark"></div>
    <section class="service-bottom" id="websection">
      <div class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <WebIcon />
              <h3>{{ oldContent.Web.MainTitle }}</h3>
            </span>
            <h3 class="subheading">{{ oldContent.Web.SubHeading }}</h3>
          </div>
          <p>
            {{ oldContent.Web.Description }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.Web.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

        <div data-aos="fade-down-left" class="flex justify-center">
          <!-- <img src="@/assets/imgs/watch2.gif" /> -->
          <WebSvg />
          <!-- <img class=" " src="@/assets/animation.png" /> -->
        </div>

        <div id="blockchain" style="margin-top: 18%">
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <Bitcoin />
              <h3>
                {{ oldContent.Blockchain.MainTitle }}
              </h3>
            </span>
          </div>
          <p>
            {{ oldContent.Blockchain.Description[0] }}
          </p>
          <p>
            {{ oldContent.Blockchain.Description[1] }}
          </p>
          <p>
            {{ oldContent.Blockchain.Description[2] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.Blockchain.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <!-- <div data-aos="fade-down-left" class="flex justify-center">
          <img class=" " src="@/assets/illustration.png" style="width:500px; height:400px"/>
        </div> -->
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 18%"
        >
          <!-- <img src="@/assets/imgs/data.gif" /> -->
          <BlockchainImg />
        </div>
      </div>
    </section>

    <div class="padding-div dark"></div>
    <div class="padding-break-rl accent"></div>

    <section class="service-top">
      <div id="design" class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <Designuiux />
              <h3>{{ oldContent.UIUX.MainTitle }}</h3>
            </span>
            <h3 class="subheading">
              {{ oldContent.UIUX.SubHeading }}
            </h3>
          </div>
          <p>
            {{ oldContent.UIUX.Description[0] }}

            <br />
            <br />
            {{ oldContent.UIUX.Description[1] }}

            <br />
            <br />
            {{ oldContent.UIUX.Description[2] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.UIUX.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>

        <div data-aos="fade-down-left" class="flex justify-center">
          <!-- <img src="@/assets/imgs/data.gif" /> -->
          <UIUXSvg />
        </div>
        <div style="margin-top: 18%">
          <div data-aos="fade-down-left">
            <span style="display: inline-flex; column-gap: 15px">
              <GrowthMarketing />
              <h3>{{ oldContent.GrowthMarketing.MainTitle }}</h3>
            </span>

            <h3 class="subheading">
              {{ oldContent.GrowthMarketing.SubHeading }}
            </h3>
          </div>
          <p>
            {{ oldContent.GrowthMarketing.Description[0] }}
            <br />
            <br />
            {{ oldContent.GrowthMarketing.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.GrowthMarketing.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 18%"
        >
          <img class="growth-img" src="@/assets/imgs/building.gif" />
          <!-- <GrowthMarketingSvg /> -->
        </div>
      </div>
    </section>

    <div class="padding-div accent"></div>
    <div class="padding-break-lr dark"></div>
    <section class="service-bottom last-element">
      <div id="digitalMarketing" class="grid container">
        <div>
          <div data-aos="fade-down-left" class="flex">
            <DigitalMarketing style="width: 90px; margin-right: 5px" />
            <h3>
              {{ oldContent.DigitalMarketing.MainTitle }}
            </h3>
          </div>
          <p>
            {{ oldContent.DigitalMarketing.Description[0] }}
            <br />
            <br />
            {{ oldContent.DigitalMarketing.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.DigitalMarketing.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div data-aos="fade-down-left" class="flex justify-center">
          <!-- <img src="@/assets/imgs/services.gif" /> -->
          <DigitalMarketingSvg />
        </div>
        <div>
          <div data-aos="fade-down-right" style="margin-top: 18%">
            <span style="display: inline-flex; column-gap: 15px">
              <NextGen style="width: 20%" />
              <h3>{{ oldContent.NextGen.MainTitle }}</h3>
            </span>
            <h3 class="subheading">{{ oldContent.NextGen.SubHeading }}</h3>
          </div>
          <p>
            {{ oldContent.NextGen.Description[0] }}
            <br />
            <br />
            {{ oldContent.NextGen.Description[1] }}
          </p>

          <ul
            class="customlist"
            v-for="sin in oldContent.NextGen.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="width: 100%; max-width: 100%; margin-top: 18%"
        >
          <!-- <img src="@/assets/imgs/next.gif" /> -->
          <NextGenSvg />
        </div>
      </div>
    </section>

    <div class="padding-div dark"></div>
    <div class="padding-break-rl accent"></div>

    <section class="service-top">
      <div class="grid container">
        <div>
          <div data-aos="fade-down-right">
            <span style="display: inline-flex; column-gap: 15px">
              <CyberIcon />
              <h3>
                {{ oldContent.Cyber.MainTitle }}
              </h3>
            </span>
            <h3 class="subheading">
              {{ oldContent.Cyber.SubHeading }}
            </h3>
          </div>
          <p>
            {{ oldContent.Cyber.Description[0] }}
          </p>
          <p>
            {{ oldContent.Cyber.Description[1] }}
          </p>
          <ul
            class="customlist"
            v-for="sin in oldContent.Cyber.Points"
            :key="sin"
          >
            <li class="list__item">{{ sin }}</li>
          </ul>
        </div>
        <!-- <div data-aos="fade-down-left" class="flex justify-center">
          <img class=" " src="@/assets/illustration.png" style="width:500px; height:400px"/>
        </div> -->
        <div
          data-aos="fade-down-left"
          class="flex justify-center"
          style="margin-top: 15%"
        >
          <Cyber class="cyber-img" />
        </div>
      </div>
    </section>

    <div class="padding-div accent"></div>

    <div class="custom-slider">
      <div class="container flex justify-between">
        <h3>{{ this.content.DontTakeOurWord }}</h3>
        <div class="testimonials-slider-nav">
          <div id="testimonials-nav-btn-left" class="testimonials-nav-btn">
            <i class="fa fa-arrow-left"></i>
          </div>
          <div id="testimonials-nav-btn-right" class="testimonials-nav-btn">
            <i class="fa fa-arrow-right"></i>
          </div>
        </div>
      </div>
      <swiper
        :loop="true"
        :navigation="false"
        :slidesPerView="
          this.screenSize === 'lg' ? 3 : this.screenSize === 'md' ? 2 : 1
        "
        :spaceBetween="30"
        :pagination="{ clickable: true }"
        :modules="modules"
        class="mySwiper"
        ref="testimonials"
        @swiper="getSwiperRef"
      >
        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background:
                'url(' +
                require('@/assets/imgs/frame-14.png') +
                ') center left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/cannabisbit.png" />
            </div>
            <h4>
              "Gap Dynamics SURPASSED EXPECTATIONS WITH THE STUNNING AND
              IMMACULATELY-DESIGNED WEBSITE AND THEIR WORK GENERATED AN
              IMPRESSIVE NUMBER OF NEW MEMBERS. THEIR TEAM HAS A COMBINATION OF
              WARMTH AND INTELLIGENCE THAT OTHER TECH AGENCIES DON’T HAVE."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, Cannabis Bit</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background:
                'url(' + require('@/assets/imgs/frame3.png') + ') center left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/joint-store.png" />
            </div>
            <h4>
              "THE TEAM WAS FANTASTIC TO WORK WITH, FROM THE PROJECT MANAGEMENT
              SIDE TO THE PRODUCT FOLKS AND THE DESIGNERS. WE ARE THRILLED WITH
              OUR PRODUCT DESIGN AND VERY APPRECIATIVE OF THE ENTHUSIASM,
              SUPPORT AND HARD WORK DEMONSTRATED BY THE ENTIRE TEAM."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, Jointstore</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-5.png') + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/sharebius.png" />
            </div>
            <h4>
              "I CAN’T SAY ENOUGH ABOUT HOW MUCH THEY ACCOMPLISHED IN THE SHORT
              TIMEFRAME."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, Sharebius</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-8.png') + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/curasend.png" />
            </div>
            <h4>
              "THE FUNCTIONAL APP RECEIVED POSITIVE FEEDBACK DURING TESTING. A
              KNOWLEDGEABLE AND PROFESSIONAL TEAM, Gap Dynamics DELIVERED
              QUALITY SOLUTIONS ON TIME. THE TEAM’S ORGANIZATION SKILLS AND
              STRONG COMPREHENSION OF THE BUSINESS GOALS ENHANCED THE END
              RESULTS."
            </h4>
            <div class="slide-footer">
              <h2>Chris Kübler</h2>
              <p>CEO, Curasend</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-1.png') + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/codefest.png" />
            </div>
            <h4>
              "Gap Dynamics RECEIVED A PERFECT SCORE ACROSS THE BOARD FROM THE
              CLIENT, NOTING THAT THEIR UI/UX DESIGN WAS EXCEPTIONAL AND SET
              THEM APART FROM OTHER SIMILAR PROVIDERS."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, Codefest</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-2.png') + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/screenflash.png" />
            </div>
            <h4>
              "IF Gap Dynamics SOMEHOW DIDN'T ACCEPT THIS PROJECT, THERE’S NO
              ONE ELSE I'D EVEN BE COMFORTABLE GOING WITH."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, ScreenFlash</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-4.png') + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/tonino.png" />
            </div>
            <h4>
              "Gap Dynamics MET DEADLINES AND STAYED ON BUDGET. THEIR TEAM WAS
              GREAT TO WORK WITH, AND THEY WERE KNOWLEDGEABLE AND PROFESSIONAL.
              THEY DELIVER HIGH-QUALITY WORK AND ARE FRIENDLY AND WELCOMING.
              THEIR EXPERTISE IS APPARENT, AND THEY DON'T NEED TO IMPROVE IN ANY
              AREA."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, Tonino</p>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div
            class="custom-slide"
            :style="{
              background: 'url(' + require('@/assets/imgs/frame-15.png') + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }"
          >
            <div class="company-logo">
              <img src="@/assets/imgs/projects/logo/logo-ico.png" />
            </div>
            <h4>
              "PROSPECTIVE CUSTOMERS, INVESTORS, AND ADVERTISING AGENCIES HAVE
              PRAISED THE UPDATED PRODUCT’S ATTRACTIVE LOOK AND FEEL. Gap
              Dynamics’S COMPETITIVE SKILL SET, COLLABORATIVE APPROACH, AND
              ADHERENCE TO ALL DEADLINES CONTRIBUTED TO A REWARDING
              RELATIONSHIP."
            </h4>
            <div class="slide-footer">
              <h2>GP</h2>
              <p>Founder, Paybius</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </main>
</template>
<script>
//ASSETS

// import DoubleDownArrow from "@/assets/icons/double_down_arrow.svg";
import Heart from "@/assets/imgs/heart.svg";
import Strategy from "../assets/imgs/strategy.svg";
// import Illustration1 from "../assets/imgs/services.gif";
import Serarch from "../assets/imgs/research.svg";
import Branding from "../assets/imgs/branding.svg";
import Pen from "../assets/imgs/pen.svg";
import Tools from "../assets/imgs/tools.svg";
import Rock from "../assets/imgs/rock.svg";
// import AppIcon from "@/assets/icons/mob_watch-01.svg";
import Animation3 from "@/components/Animation3";
import EnterpriseIcon from "@/assets/icons/EnterpriseIcon.svg";
import Cyber from "@/assets/icons/cyber.svg";
import CyberIcon from "@/assets/icons/cyber-icon.svg";
import AppIcon from "@/assets/icons/AppIcon.svg";
import Designuiux from "@/assets/icons/Designuiux.svg";
import DigitalMarketing from "@/assets/icons/DigitalMarketing.svg";
import GrowthMarketing from "@/assets/icons/GrowthMarketing.svg";
import NextGen from "@/assets/icons/NextGen.svg";
import WebIcon from "@/assets/icons/WebIcon.svg";
// import DoubleDownArrow from "@/assets/icons/double_down_arrow.svg";
import EADG from "@/assets/icons/First-illustration.svg";
// import QuoteArrow from "@/assets/icons/quote_arrow.svg";
import WebSvg from "@/assets/icons/web_development.svg";
import BlockchainImg from "@/assets/icons/on_chain.svg";
import Bitcoin from "@/assets/icons/bitcoin.svg";
import AppSvg from "@/assets/icons/mob_watch-01.svg";
import UIUXSvg from "@/assets/icons/ui_ux.svg";
import NextGenSvg from "@/assets/icons/next_gen.svg";
// import GrowthMarketingSvg from "@/assets/icons/growth_marketing.svg";
import DigitalMarketingSvg from "@/assets/icons/digital_marketing.svg";
import { Swiper, SwiperSlide } from "swiper/vue";

import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";

//COMPONENTS

// import Button from "@/components/Button";

import { LanguageStrings } from "@/languages";
import { SeoStrings } from "@/seo";
import { Vue3Marquee } from "vue3-marquee";
import { ref } from "vue";
import "vue3-marquee/dist/style.css";
const Scroll = require("react-scroll");
const scroll = Scroll.animateScroll;
const scroller = Scroll.scroller;

export default {
  computed: {
    getCurrBg: function() {
      return this.content.testimonial_data[this.testimonial_idx]["bg"];
    },
    reduceWidth: function() {
      return this.content.testimonial_data[this.testimonial_idx]["reduceWidth"];
    },
  },

  setup() {
    const swiperRef = ref(null);
    const getSwiperRef = (swiper) => {
      swiperRef.value = swiper;
    };
    const getNextSlide = () => {
      swiperRef.value.slideNext();
    };
    const getPrevSlide = () => {
      swiperRef.value.slidePrev();
    };
    return {
      swiperRef,
      getSwiperRef,
      Navigation,
      getNextSlide,
      getPrevSlide,

      // swiperOptions: {
      //   breakpoints: {
      //     575: {
      //       slidesPerView: 2,
      //       spaceBetween: 50,
      //     },
      //   },
      // },
    };
  },

  methods: {
    //  goto(refName) {
    //     var element = this.$refs[refName];
    //     var top = element.offsetTop;
    //    element.scrollIntoView({behavior: 'smooth'});
    //     window.scrollTo(0, top);
    //   },

    increment: function() {
      if (this.testimonial_idx <= this.content.testimonial_data.length - 2) {
        this.testimonial_idx = this.testimonial_idx + 1;
      } else {
        this.testimonial_idx = 0;
      }
    },
    decrement: function() {
      if (this.testimonial_idx >= 1) {
        this.testimonial_idx = this.testimonial_idx - 1;
      } else {
        this.testimonial_idx = this.content.testimonial_data.length - 1;
      }
    },
    setSliderBreakpoint: function(width) {
      if (width >= 1200) this.screenSize = "lg";
      else if (width >= 768) this.screenSize = "md";
      else this.screenSize = "sm";
    },
  },

  data: function() {
    return {
      content: null,
      testimonial_idx: 0,
      Seo: {},
      siteLanguage: "",
      screenSize: "lg",
    };
  },
  props: ["section"],
  watch: {
    section: function(newVal) {
      scroller.scrollTo(newVal, { duration: 1500, delay: 100, smooth: true });
    },
  },
  components: {
    // Button,
    // DoubleDownArrow,
    Heart,
    Strategy,
    // Illustration1,
    Animation3,
    Serarch,
    Branding,
    Pen,
    Tools,
    Rock,
    EADG,
    WebSvg,
    UIUXSvg,
    AppSvg,
    NextGenSvg,
    // GrowthMarketingSvg,
    DigitalMarketingSvg,
    WebIcon,
    NextGen,
    GrowthMarketing,
    EnterpriseIcon,
    DigitalMarketing,
    Designuiux,
    AppIcon,
    BlockchainImg,
    Bitcoin,
    Cyber,
    CyberIcon,
    Swiper,
    SwiperSlide,

    Vue3Marquee,
  },
  created() {
    this.siteLanguage = localStorage.getItem("siteLanguage");
    this.siteLanguage == "GR"
      ? ((this.content = LanguageStrings.gr.ServicesPageUpdated),
        (this.oldContent = LanguageStrings.gr.ServicesPage),
        (this.Seo = SeoStrings.gr.Services))
      : ((this.content = LanguageStrings.en.ServicesPageUpdated),
        (this.oldContent = LanguageStrings.en.ServicesPage),
        (this.Seo = SeoStrings.en.Services));

    document.querySelector("head title").innerText = this.content.MetaTitle;
  },

  mounted() {
    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     const square = entry.target.querySelector(".web");

    //     if (entry.isIntersecting) {
    //       square.classList.add("web");
    //       return; // if we added the class, exit the function
    //     }

    //     // We're not intersecting, so remove the class!
    //     // square.classList.remove('square-animation');
    //   });
    // });

    // observer.observe(document.querySelector("#websection"));
    if (this.section) {
      scroller.scrollTo(this.section, {
        duration: 1500,
        delay: 1500,
        smooth: true,
      });
    } else {
      scroll.scrollToTop();
    }

    // const nextSlideBtn = document.querySelector(".swiper-button-next");
    // const prevSlideBtn = document.querySelector(".swiper-button-prev");
    const nextSlideBtn = document.querySelector("#testimonials-nav-btn-right");
    const prevSlideBtn = document.querySelector("#testimonials-nav-btn-left");
    nextSlideBtn.addEventListener("click", this.getNextSlide);
    prevSlideBtn.addEventListener("click", this.getPrevSlide);

    // Testimonials slider breakpoints
    this.setSliderBreakpoint(window.innerWidth);
    window.addEventListener("resize", (e) => {
      this.setSliderBreakpoint(e.target.innerWidth);
    });
  },
};
</script>
